interface GetAccuFromUrlParams {
    queryParamAccu: Record<string, string> | undefined;
}

/**
 * Retrieves the value of the "accu" query parameter from the URL.
 *
 * This function first attempts to extract the "accu" parameter from the provided
 * `queryParamAccu` object (angulars $location.search()). If it is not found, it falls back to manually parsing
 * the query string from the URL before the hash (`#`) symbol.
 *
 * @param {Record<string, string> | undefined} params.queryParamAccu - An object
 * containing query parameters, where the "accu" parameter may be present.
 *
 * @returns {string | null} - The value of the "accu" query parameter if found,
 * or `null` if it is not present.
 */
const getAccuFromUrl = ({ queryParamAccu }: GetAccuFromUrlParams): string | null => {
    // Step 1: Try Angular's $location.search()
    if (queryParamAccu?.accu) return queryParamAccu.accu;

    // Step 2: Fallback — manually parse the query string before the hash
    // in case Angular's $location.search() doesn't detect it.
    // This handles cases where query parameters appear before the hash (#).
    const href = window.location.href;
    const [beforeHash] = href.split("#");
    const queryString = beforeHash.split("?")[1];

    const params = new URLSearchParams(queryString);

    return params.get("accu");
};

export default getAccuFromUrl;
