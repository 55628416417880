import { type ChangeEvent } from "react";
import { format } from "./format";

// Pattern to allow only numeric ZIP codes with an optional hyphen
const ZIP_CODE_PATTERN = /(\d{5})(\d{0,4})/;
const ZIP_CODE_SEPARATOR = "-";

export const formatZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^0-9-]/g, "");
    return format(event, ZIP_CODE_PATTERN, ZIP_CODE_SEPARATOR);
};
