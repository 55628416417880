import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import { getAuthData } from "../../services/authentication/AuthenticationService";
import { type AuthData } from "../../types";

const useAuthData = (key: string = "") => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_AUTH_DATA + key],
        queryFn: (): Promise<AuthData> => getAuthData()
    });
};

export default useAuthData;
