import React, { type FocusEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../../queries";
import { formatSSN, validSSN } from "../../../utils/formUtils";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
import { getAccuCode } from "../../../services/accuCodeService";
import { stripNonDigits } from "../../../utils/formUtils/stripNonDigits";
import registerWithPin from "../../../services/register/registerWithPin";
import useRedirect from "../../../hooks/useRedirect";
import handleRegisterServiceError from "../../../services/register/handleRegisterServiceError";

export interface RegisterWithPinTranslations {
    app: { beneficiary: { bene: { validationMessages: { socialSecurityNumberInvalid: string } } } };
    button: { continue: string };
    idProofingEnabled: string;
    individual: {
        pin: string;
        ssn: string;
    };
    logon: { [key: string]: string };
    pinRequired: string;
    ssnRequired: string;
}

interface RegisterWithPinFields {
    pin: string;
    ssn: string;
}

const DEFAULT_VALUES = {
    ssn: "",
    pin: ""
};

const FLOW_NAME = "registrationFlow";

const RegisterWithPin = () => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<RegisterWithPinFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const {
        app,
        button,
        idProofingEnabled,
        individual,
        logon: errorMessages,
        pinRequired,
        ssnRequired
    } = useTranslations<RegisterWithPinTranslations>();
    const [typeSSN, setTypeSSN] = useState("text");
    const [typePin, setTypePin] = useState("number");
    const redirect = useRedirect();

    /**
     * The following defines the validations for each field. Each validation must return undefined
     * if the rule is not violated in order to remove it from the field's error object.
     */
    const SSN_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? ssnRequired : undefined),
            isValid: (value: string) =>
                !validSSN(value)
                    ? app.beneficiary.bene.validationMessages.socialSecurityNumberInvalid
                    : undefined
        }
    };
    const PIN_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? pinRequired : undefined)
        }
    };

    /**
     * When the SSN field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurSSN = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (validSSN(event.target.value)) {
            setTypeSSN("password");
        }
    };

    /**
     * When the SSN field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusSSN = () => {
        setTypeSSN("text");
    };

    /**
     * When the pin field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurPin = () => {
        setTypePin("password");
    };

    /**
     * When the pin field gains focus, set the field type to 'number' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusPin = () => {
        setTypePin("number");
    };

    const onSubmit = async (formData: RegisterWithPinFields) => {
        const { ssn, pin } = formData;
        const payload = {
            pin,
            accu: getAccuCode(),
            flowName: FLOW_NAME,
            isIDProofing: idProofingEnabled,
            ssn: stripNonDigits(ssn)
        };
        try {
            const { data } = await registerWithPin(payload);
            redirect(data);
        } catch (err) {
            const message = handleRegisterServiceError(errorMessages, err);
            setError("root", { message });
        }
    };

    return (
        <form data-testid="register-with-pin" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}
            {errors?.root?.message && (
                <div
                    data-testid="register-with-pin-error"
                    className="error-block margin-bottom-100"
                    aria-live="polite"
                >
                    {errors.root.message}
                </div>
            )}
            <ControlledFormField
                className="col-5"
                control={control}
                id="ssn"
                label={individual.ssn}
                name="ssn"
                onBlur={handleBlurSSN}
                onChange={formatSSN}
                onFocus={handleFocusSSN}
                rules={SSN_RULES}
                type={typeSSN}
            />
            <ControlledFormField
                className="col-5"
                control={control}
                id="pin"
                label={individual.pin}
                maxLength={16}
                name="pin"
                onBlur={handleBlurPin}
                onFocus={handleFocusPin}
                rules={PIN_RULES}
                type={typePin}
            />
            <div className="form-group">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                >
                    <span>{button.continue.toUpperCase()}</span>
                </button>
            </div>
        </form>
    );
};

export default RegisterWithPin;
