import React from "react";
import VerificationCodeEntry from "./verificationCodeEntry/VerificationCodeEntry";
import { HashRouter, Route, Routes } from "react-router-dom";
import {
    ACTIVATION_CODE_DELIVERY_OPTIONS,
    VERIFY_CODE,
    LINKABLE_PLANS,
    CHOOSE_PRIMARY_PLAN,
    CONTACT_UPDATE,
    LINKED_CONFIRMATION
} from "../../routes";

import DeliveryOptions from "./deliveryOptions/DeliveryOptions";
import LinkablePlansComponent from "../oneId/LinkablePlansComponent";

import ContactUpdate from "../accountSetup/ContactUpdate";
import ChoosePrimaryPlan from "../oneId/ChoosePrimaryPlan";
import LinkedConfirmationComponent from "../oneId/components/LinkedConfirmationComponent";

interface MFAProps {
    $location: { path: (path: string) => void };
    $scope: { $apply: () => void; $root: { featureName: string } };
    linkingContext?: string;
    redirectService: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
}

/**
 * TODO: DELETE THIS COMPONENT WHEN ANGULAR IS REMOVED.
 * This is a temporary component that provides access to react router, react query, and the translations hook
 * while still allowing the new MFA components to be embedded in the Angular application.
 * Also temporarily pass the redirectService from the Angular app until that has been converted.
 */
const MFA = ({ redirectService: { redirect }, $location, $scope, linkingContext }: MFAProps) => {
    return (
        <HashRouter>
            <Routes>
                <Route
                    path={ACTIVATION_CODE_DELIVERY_OPTIONS}
                    element={
                        <DeliveryOptions
                            legacyRedirect={redirect}
                            linkingContext={linkingContext}
                            location={$location}
                            scope={$scope}
                        />
                    }
                ></Route>
                <Route
                    path={VERIFY_CODE}
                    element={
                        <VerificationCodeEntry
                            legacyRedirect={redirect}
                            linkingContext={linkingContext}
                        />
                    }
                />

                <Route
                    path={LINKABLE_PLANS}
                    element={<LinkablePlansComponent location={$location} scope={$scope} />}
                ></Route>
                <Route
                    path={CHOOSE_PRIMARY_PLAN}
                    element={<ChoosePrimaryPlan legacyRedirect={redirect} oneIdFlow="" />}
                ></Route>
                <Route path={LINKED_CONFIRMATION} element={<LinkedConfirmationComponent />}></Route>
                <Route
                    path={CONTACT_UPDATE}
                    element={<ContactUpdate legacyRedirect={redirect} />}
                ></Route>
            </Routes>
        </HashRouter>
    );
};

export default MFA;
