import { stripNonDigits } from "./stripNonDigits";
import { type ChangeEvent } from "react";

const calculateSelectionStart = (
    value: string,
    selectionStart: number,
    formattingFn: (value: string) => string
) => {
    const part = value.slice(0, selectionStart);
    const formattedPart = formattingFn(part);
    const shift = formattedPart.length - part.length;
    return selectionStart + (shift > 0 ? shift : 0);
};

const addSeparator = (value: string, pattern: RegExp, separator: string) => {
    const strippedValue = stripNonDigits(value);
    const match = strippedValue.match(pattern);
    let updatedValue = strippedValue;
    if (match) {
        updatedValue = match[1];
        for (let i = 2; i < match.length; i++) {
            if (match[i]) {
                updatedValue += separator;
                updatedValue += match[i];
            }
        }
    }
    return updatedValue;
};

export const format = (
    event: ChangeEvent<HTMLInputElement>,
    pattern: RegExp,
    separator: string = ""
) => {
    const { value, selectionStart } = event.target;
    const updatedValue = addSeparator(value, pattern, separator);
    const updatedSelectionStart = calculateSelectionStart(value, selectionStart || 0, (s: string) =>
        addSeparator(s, pattern, separator)
    );
    event.target.value = updatedValue;
    event.target.setSelectionRange(updatedSelectionStart, updatedSelectionStart);
    return event;
};
