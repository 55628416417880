import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executeGet } from "../../../utils/appUtils";
import { type RedirectData } from "../../redirect/types";

const registrationSummaryDestination = (): Promise<AxiosResponse<RedirectData>> => {
    return executeGet(
        `${ReferenceData.REGISTRATION_SUMMARY}/destination`,
        "registrationSummaryDestinationFault"
    );
};

export default registrationSummaryDestination;
