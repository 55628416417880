import React, { useState, useEffect } from "react";
import AllFlowsContactInfoUpdate from "./AllFlowsContactInfoUpdate";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../queries";
import SubmitButton from "./SubmitButton";
import { useMutation } from "@tanstack/react-query";
import { getUpdateContactInfoJson } from "./helpers";
import { updateContact } from "../../services/accountSetup/accountSetupService";
import type { RegisterFields, SaveErrorData, SaveErrorState, SetupTranslations } from "./types";
import useRedirect from "../../hooks/useRedirect";
import { type RedirectData } from "../../services/redirect/types";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import RegisterEvents from "../../../accountSetup/events/register";

interface ContactUpdateProps {
    legacyRedirect?: (data: { state: string }, key: string) => void;
}

/**
 * Same component used in accountSetup and registration contactUpdate
 * @returns
 */
const ContactUpdate = ({ legacyRedirect }: ContactUpdateProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        control
    } = useForm<RegisterFields>();
    const { accountSetup }: { accountSetup: SetupTranslations } = useTranslations();
    const [saveError, setSaveError] = useState<SaveErrorState | null>(null);
    const redirect: any = useRedirect();

    const mutation = useMutation({
        mutationFn: updateContact,
        onSuccess: (data: RedirectData) => {
            if (legacyRedirect) {
                legacyRedirect(data, "ALL");
            } else {
                redirect(data);
            }
        },
        onError: (err: SaveErrorData) => {
            const errData = err.data.error;
            setSaveError(errData);
        }
    });

    const updateContactDetails = (data, flowName) => {
        const payload = getUpdateContactInfoJson(data, flowName);
        mutation.mutate(payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: RegisterEvents.SUCCESSFUL_SUBMIT_VERIFY_CONTACT_INFO
        });
    };

    const showSpinner = mutation.status === "pending";

    const handleValidationError = () => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: RegisterEvents.ATTEMPT_SUBMIT_VERIFY_CONTACT_INFO
        });
    };

    useEffect(() => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.VIEW_PAGE,
            selection: RegisterEvents.PGE_VIEW_VERIFY_CONTACT_INFO
        });
    }, []);

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                    <header className="contained-form-header">
                        <h1>{accountSetup.completeContactInfoTitle}</h1>
                        <p>{accountSetup.completeRegistrationInfo}</p>
                    </header>

                    <div className="inner-container with-padding with-shadow">
                        <form
                            id="registration-form"
                            name="accountSetupForm"
                            className="clearfix"
                            onSubmit={handleSubmit(
                                (data) => updateContactDetails(data, "mfa"),
                                handleValidationError
                            )}
                            noValidate
                            autoComplete="off"
                        >
                            <AllFlowsContactInfoUpdate
                                register={register}
                                trigger={trigger}
                                errors={errors}
                                saveError={saveError}
                                control={control}
                                isNpdiFlow={false}
                            />
                            <SubmitButton showSpinner={showSpinner} isRegistrationFlow={false} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUpdate;
