import getLegacySSOAuthToken from "../../authentication/getLegacySSOAuthToken";
import { callLogoutSvc } from "core-ui/client/react/core/services/logoutService.js";
import { type AccessPortalGroups } from "../types";

const getLegacyRedirectUrl = async (portals: AccessPortalGroups[]) => {
    try {
        const portal = portals[0].accessPortalList[0];
        const getLegacySSOAuthTokenParams = {
            indId: portal.indId,
            gaId: portal.gaId
        };
        const { data } = await getLegacySSOAuthToken(getLegacySSOAuthTokenParams);
        await callLogoutSvc();
        return `${__iframeUrl__}${portal.portal}&synctoken=${data}&gaId=${portal.gaId}`;
    } catch (error) {
        console.error("Error getting legacy redirect URL", error);
        throw error;
    }
};

export default getLegacyRedirectUrl;
