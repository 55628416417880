import React, { type FocusEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../../queries";
import { formatDate } from "../../../utils/formUtils";
import DateUtil from "core-ui/client/src/app/DateUtil";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
import { getAccuCode } from "../../../services/accuCodeService";
import { stripNonDigits } from "../../../utils/formUtils/stripNonDigits";
import registerWithoutPin from "../../../services/register/registerWithoutPin";
import handleRegisterServiceError from "../../../services/register/handleRegisterServiceError";
import useRedirect from "../../../hooks/useRedirect";

interface RegisterWithUniqueIdentifierTranslations {
    button: { continue: string };
    dateOfBirthFutureDateNotAllowed: string;
    dateOfBirthInvalid: string;
    dateOfBirthRequired: string;
    dateOfBirthYearOutOfRange: string;
    idProofingEnabled: string;
    individual: {
        dateOfBirth: string;
        lastName: string;
        planID: string;
        uniqueID: string;
    };
    lastNameRequired: string;
    logon: { [key: string]: string };
    planIDRequired: string;
    uniqueIdentifierRequired: string;
}

interface RegisterWithUniqueIdentifierFields {
    dateOfBirth: string;
    lastName: string;
    planId: string;
    uniqueIdentifier: string;
}

const DEFAULT_VALUES = {
    dateOfBirth: "",
    lastName: "",
    planId: "",
    uniqueIdentifier: ""
};

const FLOW_NAME = "registration";

const RegisterWithUniqueIdentifier = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<RegisterWithUniqueIdentifierFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const {
        button,
        dateOfBirthFutureDateNotAllowed,
        dateOfBirthInvalid,
        dateOfBirthRequired,
        dateOfBirthYearOutOfRange,
        idProofingEnabled,
        individual,
        lastNameRequired,
        logon: errorMessages,
        planIDRequired,
        uniqueIdentifierRequired
    } = useTranslations<RegisterWithUniqueIdentifierTranslations>();
    const [typeDateOfBirth, setTypeDateOfBirth] = useState("text");
    const redirect = useRedirect();

    /**
     * The following defines the validations for each field. Each validation must return undefined
     * if the rule is not violated in order to remove it from the field's error object.
     */
    const UNIQUE_IDENTIFIER_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? uniqueIdentifierRequired : undefined)
        }
    };
    const PLAN_ID_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? planIDRequired : undefined)
        }
    };
    const LAST_NAME_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? lastNameRequired : undefined)
        }
    };
    const DATE_OF_BIRTH_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? dateOfBirthRequired : undefined),
            isValid: (value: string) =>
                !DateUtil.isValid(value, "MM/DD/YYYY", true) ? dateOfBirthInvalid : undefined,
            isOutOfRange: (value: string) =>
                DateUtil.isBefore(value, DateUtil.getMinimumDateOfBirth())
                    ? dateOfBirthYearOutOfRange
                    : undefined,
            isFutureDate: (value: string) =>
                DateUtil.isAfter(value, DateUtil.getDate())
                    ? dateOfBirthFutureDateNotAllowed
                    : undefined
        }
    };

    /**
     * When the Date of Birth field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurDateOfBirth = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (DateUtil.isValid(event.target.value, "MM/DD/YYYY", true)) {
            setTypeDateOfBirth("password");
        }
    };

    /**
     * When the Date of Birth field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusDateOfBirth = () => {
        setTypeDateOfBirth("text");
    };

    const onSubmit = async (formData: RegisterWithUniqueIdentifierFields) => {
        const { dateOfBirth, lastName, planId, uniqueIdentifier } = formData;
        const payload = {
            lastName,
            accu: getAccuCode(),
            dateOfBirth: stripNonDigits(dateOfBirth),
            flowName: FLOW_NAME,
            isIDProofing: idProofingEnabled,
            metrixPlanId: planId,
            uniqueId: uniqueIdentifier
        };
        try {
            const { data } = await registerWithoutPin(payload);
            if (data.state === "ID_PROOFING_CONSENT") {
                // TODO (Maja): Trigger ID Proof Consent Modal
                // Dependency on WCDX-21096
            } else {
                redirect(data);
            }
        } catch (err) {
            const message = handleRegisterServiceError(errorMessages, err);
            control.setError("root", { message });
        }
    };

    return (
        <form data-testid="register-with-unique-identifier" onSubmit={handleSubmit(onSubmit)}>
            {isSubmitting && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}
            {errors && errors.root && errors.root.message && (
                <div
                    data-testid="register-with-unique-identifier-error"
                    className="error-block margin-bottom-100"
                    aria-live="polite"
                >
                    {errors.root.message}
                </div>
            )}
            <ControlledFormField
                className="col-5"
                control={control}
                id="unique-identifier"
                label={individual.uniqueID}
                maxLength={10}
                name="uniqueIdentifier"
                rules={UNIQUE_IDENTIFIER_RULES}
                type="number"
            />
            <ControlledFormField
                className="col-5"
                control={control}
                id="plan-id"
                label={individual.planID}
                maxLength={12}
                name="planId"
                rules={PLAN_ID_RULES}
                type="number"
            />
            <ControlledFormField
                control={control}
                id="last-name"
                label={individual.lastName}
                maxLength={35}
                name="lastName"
                rules={LAST_NAME_RULES}
            />
            <ControlledFormField
                control={control}
                id="date-of-birth"
                label={individual.dateOfBirth}
                name="dateOfBirth"
                onBlur={handleBlurDateOfBirth}
                onChange={formatDate}
                onFocus={handleFocusDateOfBirth}
                rules={DATE_OF_BIRTH_RULES}
                type={typeDateOfBirth}
            />
            <div className="form-group">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                >
                    <span>{button.continue.toUpperCase()}</span>
                </button>
            </div>
        </form>
    );
};

export default RegisterWithUniqueIdentifier;
