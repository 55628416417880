import React, { useEffect, useState, useCallback, useRef } from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const AlternateSiteDialogView = ({ show, accuError, translations }) => {
    const [seconds, setSeconds] = useState(30);
    const timerRef = useRef(null);
    const error = accuError?.errors[0];
    const init = useCallback(() => {
        if (timerRef.current) {
            return;
        }

        timerRef.current = setInterval(() => {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setSeconds((prevSeconds) => {
                if (prevSeconds <= 1) {
                    clearInterval(timerRef.current); // Clear the timer when it reaches 0
                    timerRef.current = null; // Reset the timer ref
                    redirectToCorrectAccuSite(error);
                    return 0;
                }
                return prevSeconds - 1; // Decrement the seconds
            });
        }, 1000);
    }, [error]);

    useEffect(() => {
        if (show) {
            init();
        } else if (!show && timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [show, init]);

    const redirectToCorrectAccuSite = (error) => {
        window.top.location.href = error.ALTERNATIVE_LOGN_URL;
    };
    const alternateSiteDescription = translations.description
        ? translations.description.replace("{{seconds}}", seconds)
        : "";
    return (
        <Modal dialogClassName={`${show ? "modal show" : "modal"}`} show={show}>
            {show && (
                <div className="modal-dialog bulletin">
                    <div className="modal-content">
                        <Modal.Header>
                            <h4 className="modal-title">{translations.title}</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{alternateSiteDescription}</p>
                            <button
                                className="btn-link no-underline no-padding"
                                onClick={() => {
                                    redirectToCorrectAccuSite(error);
                                }}
                            >
                                {error.ALTERNATIVE_LOGN_URL}
                            </button>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    redirectToCorrectAccuSite(error);
                                }}
                            >
                                {translations.redirectLink}
                            </button>
                        </Modal.Footer>
                    </div>
                </div>
            )}
        </Modal>
    );
};

AlternateSiteDialogView.propTypes = {
    show: PropTypes.bool,
    accuError: PropTypes.object,
    translations: PropTypes.object
};

export default AlternateSiteDialogView;
