import type { AxiosResponse } from "axios";
import { type RedirectData } from "../../redirect/types";
import { executePost } from "../../../utils/appUtils";
import ReferenceData from "../../../constants/ReferenceData";

interface RegisterWithGroupPassword {
    flowName: string;
    groupId: string;
    pin: string;
}

const registerWithGroupPassword = (
    payload: RegisterWithGroupPassword
): Promise<AxiosResponse<RedirectData>> => {
    return executePost(
        ReferenceData.REGISTER_WITH_GROUP_PASSWORD,
        "registerWithGroupPasswordFault",
        payload
    );
};

export default registerWithGroupPassword;
