import { type AuthData } from "../authenticate/authenticate";
/**
 * This method will return the accu code associated with an 'empower restricted plan' if any such plan is returned for the ppt.
 */
const getEmpowerRestrictedPlanAccuCode = (data: AuthData): string | null => {
    if (!data.accessPortalGroups || data.accessPortalGroups.length === 0 || !data.primaryPlan) {
        return null;
    }
    const accessPortalGroups = data.accessPortalGroups;
    const primaryPlan = data.primaryPlan;

    for (let i = 0; i < accessPortalGroups.length; i++) {
        const accessPortalGroup = accessPortalGroups[i];
        if (accessPortalGroup.accessPortalList) {
            for (let j = 0; j < accessPortalGroup.accessPortalList.length; j++) {
                const accessPortal = accessPortalGroup.accessPortalList[j];

                //look for a match of primary plan with accessPortalList and return the accu code associated.
                if (
                    primaryPlan.gaId === accessPortal.gaId &&
                    primaryPlan.indId === accessPortal.indId
                ) {
                    return accessPortalGroup.accu;
                }
            }
        }
    }

    return null;
};

export default getEmpowerRestrictedPlanAccuCode;
