import React, { type MouseEvent, type FocusEvent, useCallback, useState } from "react";
import { useTranslations } from "../../../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import EventBusEvents from "../../../../events/EventBusEvents";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import eventBus from "../../../../../utils/setEventBus";
import hasNumber from "../../../accountSetup/utils/hasNumber";
import hasUppercase from "../../../accountSetup/utils/hasUppercase";
import hasLowercase from "../../../accountSetup/utils/hasLowercase";
import hasSpecialChar from "../../../accountSetup/utils/hasSpecialChar";
import PasswordErrors from "../../../accountSetup/PasswordErrors";
import { resetPasswordFreemiumPcap } from "../../../../services/accountRecovery/AccountRecoveryService";
import { getCookie } from "../../../../services/accuCodeService";

interface createPasswordTranslations {
    accountSetup: {
        confirmPasswordNotMatch: string;
        confirmPasswordRequired: string;
        confirmPasswordValid: string;
        passwordAcceptable: string;
        passwordCondition0: string;
        passwordCondition1: string;
        passwordCondition2: string;
        passwordLowercasePattern: string;
        passwordMinLength: string;
        passwordNotMatchUsername: string;
        passwordNumberPattern: string;
        passwordRequired: string;
        passwordSpecialCharPattern: string;
        passwordUppercasePattern: string;
    };
    app: {
        common: {
            labels: {
                cancel: string;
                continue: string;
            };
        };
    };
    button: {
        continue: string;
    };

    forgotPasswordTitle: string;
    loginHelpCreatePassword: {
        confirmPwd: string;
        createNew: string;
        createPwd: string;
        pwdValidation: string;
    };
    logon: {
        labels: {
            hidePassLabel: string;
            passLabel: string;
            passwordHiddenAlert: string;
            passwordShownAlert: string;
            showPassLabel: string;
            userLabel: string;
        };
    };
    passwordRequired: string;
}

interface CreatePasswordFields {
    confirmPassword: string;
    password: string;
}

interface FocusState {
    confirmPassword: boolean;
    password: boolean;
}

const DEFAULT_FOCUS_STATE = {
    password: false,
    confirmPassword: false
};
const LoginHelpResetCreatePassword = () => {
    const { state } = useLocation();
    const username = state?.username;
    const csrfToken = state?.csrfToken || "";
    const {
        loginHelpCreatePassword,
        forgotPasswordTitle,
        app,
        accountSetup,
        passwordRequired,
        logon
    } = useTranslations<createPasswordTranslations>();
    const [focusState, setFocusState] = useState<FocusState>(DEFAULT_FOCUS_STATE);
    const [showEyePwd, setShowEyePwd] = useState(true);
    const [showEyeConfirmPwd, setShowEyeConfirmPwd] = useState(true);

    const {
        register,
        getValues,
        handleSubmit,
        trigger,
        formState: { errors, isSubmitting, isValid }
    } = useForm<CreatePasswordFields>({
        criteriaMode: "all",
        mode: "all"
    });
    const accu = getCookie("accu");
    const PASSWORD_RULES = {
        lengthRequirement: (value: string) =>
            value.length < 8 || value.length > 64 ? accountSetup.passwordMinLength : undefined,
        passwordConditions: (value: string) => {
            const upper = hasUppercase(value);
            const lower = hasLowercase(value);
            const digit = hasNumber(value);
            const specialChar = hasSpecialChar(value);
            if ([upper, lower, digit, specialChar].filter(Boolean).length < 3) {
                return [
                    accountSetup.passwordCondition0,
                    upper ? "" : accountSetup.passwordUppercasePattern,
                    lower ? "" : accountSetup.passwordLowercasePattern,
                    digit ? "" : accountSetup.passwordNumberPattern,
                    specialChar ? "" : accountSetup.passwordSpecialCharPattern
                ].join("|");
            }
            return undefined;
        }
    };
    const CONFIRM_PASSWORD_RULES = {
        confirmPasswordNoMatch: (value: string) =>
            value !== getValues("password") ? accountSetup.confirmPasswordNotMatch : undefined
    };
    const [resetErrorMsg, setResetErrorMsg] = useState("");

    const handleSmsCodeSubmit = async () => {
        const payload = {
            challengeReason: "PWD_RESET",
            flags: null,
            redirectTo: null,
            skipFirstUse: null,
            referrerId: null,
            passwd: getValues("password"),
            username: username,
            passwd2: getValues("confirmPassword"),
            apiClient: "WEB",
            bindDevice: false,
            csrf: csrfToken
        };
        try {
            const authData = await resetPasswordFreemiumPcap(payload);
            if (authData.spHeader.success) {
                window.location.href = `/participant/#/login?accu=${accu}`;
                window.location.reload();
            } else {
                const errorMsg =
                    authData.spHeader?.errors && authData.spHeader?.errors?.length > 0
                        ? authData.spHeader.errors[0].message
                        : "";
                setResetErrorMsg(errorMsg);
            }
            localStorage.removeItem("csrf");
        } catch (e) {
            console.error(e);
        }
    };
    const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const name = event.target.name as keyof FocusState;
            trigger(name);
            setFocusState((prev) => ({ ...prev, [name]: false }));
        },
        [trigger]
    );
    //eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const handleAmplitudeEvent = useCallback((event: FocusEvent<HTMLInputElement>) => {
        const { selection } = event.currentTarget.dataset;
        const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_FIELD, event.target);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_FIELD,
            selection: String(selection),
            payload: {
                payload
            }
        });
    }, []);

    const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            setFocusState((prev) => ({ ...prev, [event.target.name]: true }));
            handleAmplitudeEvent(event);
        },
        [handleAmplitudeEvent]
    );

    const dispatchAmplitudeEvent = useCallback(
        (event: MouseEvent<HTMLElement>, selectedButton) => {
            const { selection } = event.currentTarget.dataset;
            const updSelection = String(selection).toLowerCase().replace(/\s/g, "_");
            const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;
            eventBus.dispatch("MFAEvent.sign_in_button_clicked_event", event.target, updSelection);
            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
                selection: updSelection,
                payload: {
                    payload
                }
            });
            if (selectedButton === "cancel") {
                window.location.href = `/participant/#/login?accu=${accu}`;
            }
        },
        [accu]
    );
    const showHidePwd = (e, val) => {
        if (val === "password") {
            setShowEyePwd(!showEyePwd);
        } else {
            setShowEyeConfirmPwd(!showEyeConfirmPwd);
        }
        eventBus.dispatch(EventBusEvents.SHOW_HIDE_PWD, e.target, {
            showPwd: !showEyePwd || !showEyeConfirmPwd
        });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: EventBusEvents.SHOW_HIDE_PWD,
                payload: {
                    showPwd: !showEyePwd || !showEyeConfirmPwd
                }
            }
        });
    };

    return (
        <div className="mfa-container">
            <form
                id="forgot-password-form"
                data-testid="login-help-reset-create-password"
                autoComplete="off"
                onSubmit={handleSubmit(handleSmsCodeSubmit)}
            >
                {isSubmitting && <div className="loader"></div>}
                <h1 className="field-font-size">{forgotPasswordTitle}</h1>
                <div className="description">{loginHelpCreatePassword.createNew}</div>
                <div className="code-entry-container">
                    {errors?.root?.message && (
                        <div className="error-block" role="alert" aria-live="polite">
                            {errors.root.message}
                        </div>
                    )}
                    {resetErrorMsg && (
                        <div className="error-block" role="alert" aria-live="polite">
                            {resetErrorMsg}
                        </div>
                    )}
                    <div
                        className={`form-group ${focusState.password && errors.password && "has-error"}`}
                    >
                        <label
                            htmlFor="passwordInput"
                            className={`form-group ${
                                errors["password"] !== undefined ? "has-error error-block" : ""
                            }`}
                        >
                            {loginHelpCreatePassword.createPwd}
                        </label>
                        <div className="input-group">
                            <input
                                {...register("password", {
                                    required: passwordRequired,
                                    maxLength: 64,
                                    validate: PASSWORD_RULES
                                })}
                                autoComplete="off"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                type={showEyePwd ? "password" : "text"}
                                className="form-control"
                                id="passwordInput"
                                data-selection="create_password"
                                aria-describedby={
                                    errors.password !== undefined ? "passwordRequiredError" : ""
                                }
                            />
                            <div className="input-group-addon">
                                <span
                                    className={`fa-regular  ${!showEyePwd ? "fa-eye" : "fa-eye-slash"}`}
                                    onClick={(e) => showHidePwd(e, "password")}
                                    onKeyUp={(e) => showHidePwd(e, "password")}
                                    role="button"
                                    tabIndex={0}
                                    aria-label={
                                        showEyePwd
                                            ? logon?.labels?.showPassLabel
                                            : logon?.labels?.hidePassLabel
                                    }
                                ></span>
                                <div role="alert" className="sr-only">
                                    {showEyePwd
                                        ? logon?.labels?.passwordHiddenAlert
                                        : logon?.labels?.passwordShownAlert}
                                </div>
                            </div>
                        </div>
                        <PasswordErrors
                            errors={errors}
                            highlightErrors={focusState.password && errors.password !== undefined}
                        />
                    </div>
                    <br />
                    <div
                        className={`form-group ${focusState.confirmPassword && errors.confirmPassword && "has-error"}`}
                    >
                        <label
                            htmlFor="confirmPasswordInput"
                            className={`form-group ${
                                errors["confirmPassword"] !== undefined
                                    ? "has-error error-block"
                                    : ""
                            }`}
                        >
                            {loginHelpCreatePassword.confirmPwd}
                        </label>
                        <div className="input-group">
                            <input
                                {...register("confirmPassword", {
                                    required: accountSetup.confirmPasswordRequired,
                                    maxLength: 64,
                                    validate: CONFIRM_PASSWORD_RULES
                                })}
                                autoComplete="off"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                type={showEyeConfirmPwd ? "password" : "text"}
                                className="form-control"
                                id="confirmPasswordInput"
                                aria-describedby="confirmPasswordRequiredError"
                                data-selection="confirm_password"
                            />
                            <div className="input-group-addon">
                                <span
                                    className={`fa-regular ${!showEyeConfirmPwd ? "fa-eye" : "fa-eye-slash"}`}
                                    onClick={(e) => showHidePwd(e, "conPassword")}
                                    onKeyUp={(e) => showHidePwd(e, "conPassword")}
                                    role="button"
                                    tabIndex={0}
                                    aria-label={
                                        showEyeConfirmPwd
                                            ? logon?.labels?.showPassLabel
                                            : logon?.labels?.hidePassLabel
                                    }
                                ></span>
                                <div role="alert" className="sr-only">
                                    {showEyeConfirmPwd
                                        ? logon?.labels?.passwordHiddenAlert
                                        : logon?.labels?.passwordShownAlert}
                                </div>
                            </div>
                        </div>
                        <ul
                            id="confirmPasswordRequiredError"
                            className={
                                focusState.confirmPassword && errors.confirmPassword !== undefined
                                    ? "field-errors"
                                    : ""
                            }
                        >
                            {errors.confirmPassword?.types?.required && (
                                <li>{errors.confirmPassword.types.required}</li>
                            )}
                            {errors.confirmPassword?.types?.confirmPasswordNoMatch && (
                                <li>{errors.confirmPassword.types.confirmPasswordNoMatch}</li>
                            )}
                        </ul>
                    </div>

                    <br />
                </div>
                <div className="button-container">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={(event) => dispatchAmplitudeEvent(event, "continue")}
                        disabled={!isValid || resetErrorMsg > ""}
                        data-selection="continue"
                    >
                        {app.common.labels.continue}
                    </button>
                </div>
                <br />
                <div className="button-container">
                    <button
                        className="btn"
                        onClick={(event) => dispatchAmplitudeEvent(event, "cancel")}
                        data-selection="cancel"
                    >
                        {app.common.labels.cancel}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default LoginHelpResetCreatePassword;
