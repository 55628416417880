import type { AxiosResponse } from "axios";
import ReferenceData from "../../constants/ReferenceData";
import { executePost } from "../../utils/appUtils";
import { type CreateAndDeliverActivationCodeSuccessResponse } from "./createAndDeliverActivationCode";

interface CreateAndDeliverActivationCodeByIndIdPayload {
    accu: string | undefined;
    dbName: string;
    deliveryOption: string;
    indid: string;
}

const createAndDeliverActivationCodeByIndId = (
    payload: CreateAndDeliverActivationCodeByIndIdPayload
): Promise<AxiosResponse<CreateAndDeliverActivationCodeSuccessResponse>> => {
    return executePost(
        ReferenceData.CREATE_AND_DELIVER_ACTIVATION_CODE_BY_IND_ID,
        "createAndDeliverActivationCodeByIndIdFault",
        payload
    );
};

export default createAndDeliverActivationCodeByIndId;
