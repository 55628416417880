interface GetAccuByPriorityParams {
    cookieAccu: string | undefined;
    queryParamAccu: string | undefined;
}

/**
 * Retrieves an accu value based on priority, preferring the query parameter accu
 * over the cookie accu, and falling back to a global value if neither is provided.
 *
 * @param {string | undefined} params.queryParamAccu - The value from the query parameter accu.
 * @param {string | undefined} params.cookieAccu - The value from the cookie accu.
 * @returns {string} - The selected cookie value based on the priority order:
 *                                  query parameter accu > cookie accu > global value.
 */
const getAccuByPriority = ({ queryParamAccu, cookieAccu }: GetAccuByPriorityParams): string => {
    if (!queryParamAccu) {
        return cookieAccu || globalThis.accu;
    }
    return queryParamAccu;
};

export default getAccuByPriority;
