const accountSetupFactory = function ($resource) {
    const getContactInformationUrl = "rest/partialauth/contactInformation/accountInfo";

    const updatePersonalContactApi =
        "/participant-web-services/rest/partialauth/profile/updatePersonalContact";

    const registrationUrl = "rest/partialauth/contactInformation/registerUser";

    const updateContactApi = "rest/updateContact";

    return {
        getContactInfo: $resource(
            __authenticationUrl__ + getContactInformationUrl,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        updatePersonalContact: $resource(
            __baseApiUrl__ + updatePersonalContactApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),
        registerUser: $resource(
            __authenticationUrl__ + registrationUrl,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),
        updateContact: $resource(
            __authenticationUrl__ + updateContactApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    }; //End of Return
};

accountSetupFactory.$inject = ["$resource", "$window"];
export default accountSetupFactory;
