import authenticationTemplate from "./authentication/authenticationTemplate";
import oauthLoginTemplate from "./oauth/oauthLoginTemplate";
import preconsentTemplate from "./oauth/preconsentTemplate";
import loginHelpResetPasswordDeliveryOptionsTemplate from "./accountRecovery/loginHelpResetPasswordDeliveryOptionsTemplate";

const routeStates = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");

    $stateProvider

        // Login View =================================
        .state("home", {
            url: "/login",
            template: authenticationTemplate,
            controller: "authenticationController"
        })
        .state("homeSFD", {
            url: "/sfd-login?accu",
            template: authenticationTemplate,
            controller: "authenticationController"
        })
        .state("homeDTW", {
            url: "/loginDTW",
            template: authenticationTemplate,
            controller: "authenticationController"
        })
        .state("homePart", {
            url: "/loginPart",
            template: authenticationTemplate,
            controller: "authenticationController"
        })
        .state("Aggregator", {
            url: "/oauth/login",
            template: oauthLoginTemplate,
            controller: "authenticationController"
        })
        .state("oauthLoginWithAccu", {
            url: "/oauth/login?accu=",
            template: oauthLoginTemplate,
            controller: "authenticationController"
        })
        .state("consent", {
            url: "/oauth/preconsent",
            template: preconsentTemplate,
            controller: "oauthController",
            data: {
                fullHeight: true,
                title: "Loading..."
            }
        })
        .state("loginHelpResetPasswordDeliveryOptions", {
            url: "/resetPasswordDelivery/login?token=",
            template: loginHelpResetPasswordDeliveryOptionsTemplate,
            controller: "authenticationController",
            data: {
                fullHeight: true,
                title: "Forgot Password"
            }
        })
        .state("inbound", {
            url: "/inbound",
            onEnter: function ($state, StateDestinationFactory) {
                StateDestinationFactory.getInBoundUser().$promise.then(
                    function (data) {
                        console.log("getInboundUser inovked", data);
                    },
                    function (error) {
                        console.log("getInboundUser error", error);
                    }
                );
            },
            resolve: {
                StateDestinationFactory: function (StateDestinationFactory) {
                    return StateDestinationFactory;
                }
            }
        });
};

routeStates.$inject = ["$stateProvider", "$urlRouterProvider"];
export default routeStates;
