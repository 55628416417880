import { type CustomNode } from "../../types";

const findNodeData = ({
    domNode,
    tagType
}: {
    domNode: CustomNode;
    tagType: Element["nodeName"];
}) => {
    if (tagType === "text" && domNode.firstChild) {
        return domNode.firstChild.data || "";
    }

    return tagType === "img" ? domNode.attribs?.src : domNode.attribs?.href;
};

export default findNodeData;
