import { useQuery } from "@tanstack/react-query";
import { getPreLoginAccuRetrieval } from "../../services/accuCodeService";
import { QUERY_KEYS } from "../constants";
import { type PreLoginAccuRetrieval } from "../../types";

const usePreLoginAccuRetrieval = (currentAccu: string | undefined) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_PRE_LOGIN_ACCU_RETRIEVAL, currentAccu],
        queryFn: (): Promise<PreLoginAccuRetrieval> => getPreLoginAccuRetrieval(currentAccu),
        enabled: !!currentAccu
    });
};

export default usePreLoginAccuRetrieval;
