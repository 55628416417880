import _findIndex from "lodash/findIndex";
import _isNumber from "lodash/isNumber";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import localBuildConfig from "../../../../build/buildConfig.json";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import redwoodAuthenticateToken from "../login/services/redwoodAuthenticateToken";
import getRegisterFreemiumToken from "../login/services/getRegisterFreemiumToken";
import loadFtd from "../../app/login/components/loginForm/helpers/loadFtd";

const registrationFreemiumController = function (
    $injector,
    $modal,
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $translate,
    $location,
    $window
) {
    const logger = ExternalLogger.getInstance("registrationFreemiumController");
    const multiAuth = localBuildConfig.multiAuth;
    $scope.setPcBaseApiUrl = multiAuth[$window.subdomain];
    const handleRewoodDashbaord = async function () {
        //try to get idToken from cookie and remove query string if not found redirect to login
        let skipFirstUse = false;
        let marketingParam = null;
        try {
            const getIdTokenResponse = await getRegisterFreemiumToken($scope.setPcBaseApiUrl);

            skipFirstUse = getIdTokenResponse.data.spData.skipFirstUse
                ? getIdTokenResponse.data.spData.skipFirstUse
                : false;
            marketingParam = getIdTokenResponse.data.spData.marketing_param
                ? getIdTokenResponse.data.spData.marketing_param
                : null;

            $scope.getidToken = getIdTokenResponse.data.spData.idToken;
        } catch (error) {
            logger.error("ERROR - getRegisterFreemiumToken api: ", error);
        }
        if ($scope.getidToken) {
            const redwoodResponse = await redwoodAuthenticateToken(
                $scope.setPcBaseApiUrl,
                $scope.getidToken
            );

            if (marketingParam) {
                sessionStorage.setItem("marketingParam", marketingParam);
            }

            if (skipFirstUse) {
                sessionStorage.setItem("skipFirstUse", skipFirstUse);
            }

            loadFtd(redwoodResponse, null, $state);
        } else {
            const loginPageUrl = "participant/#/login";
            $window.location.href = "../../" + loginPageUrl;
        }
    };

    handleRewoodDashbaord();
};

registrationFreemiumController.$inject = [
    "$injector",
    "$modal",
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$translate",
    "$location",
    "$window"
];

export default registrationFreemiumController;
