import { useQuery } from "@tanstack/react-query";
import { getAccountDetails } from "../../services/account/AccountService";
import { QUERY_KEYS } from "../constants";
import { type AccountDetails } from "../../types";

const useAccountDetailsByIds = ({
    groupId,
    individualId
}: {
    groupId?: string;
    individualId?: string;
}) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ACCOUNT_DETAILS, groupId, individualId],
        queryFn: (): Promise<AccountDetails> | undefined =>
            getAccountDetails({ individualId, groupId }),
        enabled: !!(groupId && individualId)
    });
};

export default useAccountDetailsByIds;
