import React, { useState, useEffect } from "react";
import { useTranslations } from "../../queries";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import EventBusEvents from "../../events/EventBusEvents";
import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";
import { type BulletinItem } from "../../types/PreLoginBulletin";

interface BulletinTranslations {
    bulletin: {
        button: {
            dismiss: string;
            next: string;
        };
    };
}

interface PreLoginBulletinProps {
    bulletins: BulletinItem[];
}

const eventBus = new EventBus(window, document);

const PreLoginBulletin = ({ bulletins }: PreLoginBulletinProps) => {
    const { bulletin } = useTranslations<BulletinTranslations>();
    const [bulletinItem, setBulletinItem] = useState(bulletins[0]);

    const last = bulletins.length - 1;
    const clsShow = bulletins && bulletins.length > 0 ? "show" : "";

    useEffect(() => {
        // Set focus on modal on show
        const bulletinModal = document.querySelector<HTMLDivElement>(".modal-dialog");
        bulletinModal?.focus();
    }, []);

    const bulletinNext = (e) => {
        e.preventDefault();

        bulletins.shift();

        if (bulletins.length > 0) {
            const bulletinModal = document.querySelector(".bulletins");
            const bulletinModalContent = document.querySelector<HTMLDivElement>(".modal-content");
            bulletinModalContent?.focus();

            // fade out
            if (bulletinModal) {
                bulletinModal?.classList.remove("show");
            }

            setBulletinItem(bulletins[0]);
            // fade in
            if (bulletinModal) {
                setTimeout(() => {
                    bulletinModal?.classList.add("show");
                }, 500);
            }

            eventBus.dispatch(EventBusEvents.BULLETIN_NEXT, this, { bulletin: bulletin });
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: EventBusEvents.BULLETIN_NEXT,
                    payload: { bulletin: bulletin }
                }
            });
        } else {
            setBulletinItem(bulletins[0]);
            eventBus.dispatch(EventBusEvents.BULLETIN_DISMISS, this, { bulletin: bulletin });
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: EventBusEvents.BULLETIN_DISMISS,
                    payload: { bulletin: bulletin }
                }
            });
        }
    };
    /**
     * On Dismiss
     *   On key down - set focus on header, site logo
     *
     *
     */
    const bulletinDismiss = () => {
        function addFocus(e) {
            const firstElement = document.querySelector<HTMLAnchorElement>(".site-logo-wrapper a");

            e.preventDefault();
            firstElement?.focus();
            document.removeEventListener("keydown", addFocus);
        }

        document.addEventListener("keydown", addFocus);

        document.addEventListener("click", function (e) {
            const element: any = e.target;
            const classList = Array.from(element?.classList);

            if (classList.indexOf("form-control") !== -1) {
                document.removeEventListener("keydown", addFocus);
            }
        });
    };

    return (
        <>
            {bulletins && bulletins.length > 0 && (
                <div className={`modal bulletins ${clsShow}`} data-testid="pre-login-bulletin">
                    <dialog
                        aria-describedby="bullet-info"
                        aria-labelledby="bullet-title"
                        aria-modal="true"
                        className="modal-dialog bulletin"
                    >
                        <div className="modal-content" id={String(bulletinItem.bulletinId)}>
                            <div className="modal-header">
                                {bulletinItem.subject && (
                                    <h4 id="bullet-title" className="modal-title">
                                        {bulletinItem.subject}
                                    </h4>
                                )}
                                {bulletinItem.subSubject && <h5>{bulletinItem.subSubject}</h5>}
                            </div>

                            <div className="modal-body">
                                <p
                                    id="bullet-info"
                                    dangerouslySetInnerHTML={{ __html: bulletinItem.information }}
                                />
                            </div>

                            <div className="modal-footer">
                                {last !== 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-link btn-dismiss"
                                        data-dismiss="modal"
                                        onClick={bulletinNext}
                                        onKeyDown={(e) => {
                                            bulletinNext(e);
                                        }}
                                    >
                                        {bulletin.button.next}
                                    </button>
                                )}
                                {last === 0 && (
                                    <button
                                        type="button"
                                        className="btn btn-link btn-dismiss"
                                        data-dismiss="modal"
                                        onClick={bulletinNext}
                                        onKeyDown={(e) => {
                                            bulletinNext(e);
                                            bulletinDismiss();
                                        }}
                                    >
                                        {bulletin.button.dismiss}
                                    </button>
                                )}
                            </div>
                        </div>
                    </dialog>
                </div>
            )}
        </>
    );
};

export default PreLoginBulletin;
