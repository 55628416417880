import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import _isEmpty from "lodash/isEmpty.js";

import authEvents from "./events/AuthenticationEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { sentryLoginAppStartMetrics } from "../utils/sentryMetricsLogging";
import { getAccuFromUrl } from "../login/components/loginForm/helpers";

const AMPLITUDE_SFD_LOGIN_FLAG_KEY = "sfd-enabled";

const Authenticate = function (
    $location,
    $rootScope,
    $sce,
    $scope,
    $stateParams,
    eventBus,
    $state,
    $cookies
) {
    const logger = ExternalLogger.getInstance("AuthenticationController");
    $rootScope.ssoLogin = false;
    $scope.authentication.errorMessage = null;
    $scope.authentication.errorMessageParams = null;
    $scope.authentication.userId = null;
    $scope.emptyDeliveryOptionsMessage = "";

    $rootScope.isResetPasswordDelivery = String(window.location.href).includes(
        "resetPasswordDelivery"
    );

    // landing on /login is when we want to log the login start time
    sentryLoginAppStartMetrics();

    document.addEventListener(
        "tooglePwdView",
        function () {
            eventBus.dispatch(authEvents.SHOW_HIDE_PWD, this);
        },
        false
    );

    if ($stateParams.errorCode !== undefined) {
        $scope.authentication.errorMessage = $stateParams.errorCode;
        //in the case of 'noSession'
        if ($scope.authentication.errorMessage === "noSession") {
            logger.debug("AuthenticationController - noSession detected");
            $scope.resetAuthForm();
        }
    }

    $scope.removeBulletin = function (item) {
        const index = $scope.bulletins.indexOf(item);
        $scope.bulletins.splice(index, 1);
    };

    $scope.isActive = function (route) {
        return route === $location.path();
    };

    const setQueryParams = (queryParams = []) => {
        if (_isEmpty(queryParams)) return null;
        queryParams.map((param) => {
            const [a, b] = param;
            $location.search(a, b);
        });
    };

    setQueryParams($rootScope.deepLinkQueryParams);

    /**
     * Tell the Strict Contextual Escaping service to trust the string as HTML. This allows additional HTML
     * such as inline styles and inline CSS to be used with ng-bind-html. Without telling the service to
     * trust the HTML, Angular will sanitize the content before rendering.
     *
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     * DO NOT USE THIS METHOD WITH USER-ENTERED DATA OR DATA FROM UNTRUSTED SOURCES. THIS APPROACH EXPOSES *
     * THE SITE TO XSS VULNERABILITIES IF USED WITH UNTRUSTED SOURCES.                                     *
     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
     *
     * @param  {String} source The source string to trust as HTML
     * @return {Object}        A wrapped version of the source that will bypass Angular's sanitization
     */
    $scope.trustAsHtml = function (source) {
        return $sce.trustAsHtml(source);
    };

    //-------EVENTS--------
    $scope.bulletinNext = function (bulletin) {
        $scope.removeBulletin(bulletin);
        eventBus.dispatch(authEvents.BULLETIN_NEXT, this, { bulletin: bulletin });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: authEvents.BULLETIN_NEXT,
                payload: { bulletin: bulletin }
            }
        });
    };

    $scope.bulletinDismiss = function (bulletin) {
        function addFocus(e) {
            const firstElement = document.querySelector(".site-logo-wrapper a");
            const isTabPressed = e.key === "Tab" || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            e.preventDefault();
            firstElement.focus();
            document.removeEventListener("keydown", addFocus);
        }

        document.addEventListener("keydown", addFocus);

        document.addEventListener("click", function (e) {
            const element = e.target;
            const classList = Array.from(element.classList);

            if (classList.indexOf("form-control") !== -1) {
                document.removeEventListener("keydown", addFocus);
            }
        });

        $scope.removeBulletin(bulletin);
        eventBus.dispatch(authEvents.BULLETIN_DISMISS, this, { bulletin: bulletin });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: authEvents.BULLETIN_DISMISS,
                payload: { bulletin: bulletin }
            }
        });
    };

    $scope.usernameChange = function () {
        eventBus.dispatch(authEvents.USERNAME, this);
    };

    $scope.passwordChange = function () {
        eventBus.dispatch(authEvents.PASSWORD, this);
    };

    $scope.emailChange = function () {
        eventBus.dispatch(authEvents.CONTACT_EMAIL, this);
    };

    $scope.phoneChange = function () {
        eventBus.dispatch(authEvents.CONTACT_PHONE, this);
    };

    $scope.contactContinue = function () {
        eventBus.dispatch(authEvents.CONTACT_CONTINUE, this);
    };

    async function handleLoginExperimentFlow(isLoginPart) {
        if (isLoginPart) {
            // Pass the query params to the new SFD login page.
            const currentQueryParams = $location.search();
            const cookieAccu = $cookies.get("accu");

            const urlParamsAccu =
                getAccuFromUrl({
                    queryParamAccu: currentQueryParams,
                    cookieAccu,
                    $location
                }) ||
                cookieAccu ||
                globalThis.accu;

            // Fetch variants and await the promise result.
            const experiments = await globalThis.empower.eventBus.Experiment.fetch();

            if (experiments.variant(AMPLITUDE_SFD_LOGIN_FLAG_KEY).value === "on") {
                $state.go("homeSFD", { ...currentQueryParams, accu: urlParamsAccu }); // Redirect user
            }
        }
    }

    // While at /login check the Amplitude Variant to determine if we redirect to the new SFD login page /sfd-login.
    if ($location.path() === "/login" && globalThis.empower.eventBus.Experiment) {
        // This experience is controlled by both an Ampltitude flag and an AccuCustomization flag.
        $rootScope.$watch("accuCustomization", function (data) {
            // Call on controller initialization
            handleLoginExperimentFlow(data?.isLoginPart);
        });
    }
};

Authenticate.$inject = [
    "$location",
    "$rootScope",
    "$sce",
    "$scope",
    "$stateParams",
    "eventBus",
    "$state",
    "$cookies"
];
export default Authenticate;
