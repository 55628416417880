import { useQuery } from "@tanstack/react-query";
import { setAccountDetails } from "../../services/account/AccountService";
import { QUERY_KEYS } from "../constants";
import { type AccountDetails, type AuthData } from "../../types";

const useAccountDetails = (authDataResults: AuthData | undefined) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SET_ACCOUNT_DETAILS],
        queryFn: (): Promise<AccountDetails> | undefined => setAccountDetails(),
        enabled: !!authDataResults?.authenticated
    });
};

export default useAccountDetails;
