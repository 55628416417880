import React from "react";
import { find as _find } from "lodash";
import { type NpdiPayStubInfoProps } from "./types";

const NpdiPayStubInfo = ({
    register,
    stateAgency,
    errors,
    npdiForm,
    triggerValidation,
    npdiData
}: NpdiPayStubInfoProps) => {
    const divisionInputName = stateAgency ? "employerName" : "employeeRCD";
    const divisionInputError = errors[divisionInputName];

    return (
        <div className="row">
            {stateAgency && (
                <div
                    className={`form-group col-xs-12 col-sm-4 ${errors.payGroupId && "has-error"}`}
                >
                    <label htmlFor="payGroupIdInput" className="control-label">
                        {npdiForm.employmentInfo.payGroupId}
                    </label>
                    <input
                        {...register("payGroupId", {
                            required: npdiForm.payGroupRequired,
                            minLength: { value: 3, message: npdiForm.payGroupInvalidLength },
                            pattern: {
                                value: /^[a-zA-Z0-9]+$/,
                                message: npdiForm.payGroupInvalidSpecialChar
                            },
                            onBlur: (e) => triggerValidation(e)
                        })}
                        type="text"
                        name="payGroupId"
                        id="payGroupIdInput"
                        className="form-control"
                    />
                    {errors.payGroupId && errors.payGroupId.message instanceof String && (
                        <p className="error-text">{errors.payGroupId.message}</p>
                    )}
                </div>
            )}
            {stateAgency && (
                <div
                    className={`form-group col-xs-12 col-sm-4 ${errors.employeeId && "has-error"}`}
                >
                    <label htmlFor="employeeIdInput" className="control-label">
                        {npdiForm.employmentInfo.employeeId}
                    </label>
                    <input
                        {...register("employeeId", {
                            required: npdiForm.employeeIdRequired,
                            minLength: { value: 6, message: npdiForm.employeeIdInvalidLength },
                            pattern: {
                                value: /^[0-9]+$/,
                                message: npdiForm.employeeIdInvalidSpecialChar
                            },
                            onBlur: (e) => triggerValidation(e)
                        })}
                        type="text"
                        name="employeeId"
                        id="employeeIdInput"
                        className="form-control"
                    />
                    {errors.employeeId && errors.employeeId.message instanceof String && (
                        <p className="error-text">{errors.employeeId.message}</p>
                    )}
                </div>
            )}
            <div
                className={`form-group col-xs-12 col-sm-4 ${
                    errors[divisionInputName] && "has-error"
                }`}
            >
                <label htmlFor="divisionInput" className="control-label">
                    {!stateAgency
                        ? npdiForm.employmentInfo.employerName
                        : npdiForm.employmentInfo.employeeRCD}
                </label>
                <input
                    {...register(divisionInputName, {
                        required: !stateAgency
                            ? npdiForm.employerNameRequired
                            : npdiForm.employeeRcdRequired,
                        onBlur: (e) => triggerValidation(e),
                        validate: (val) => {
                            return Boolean(
                                _find(
                                    npdiData.divisionCodes,
                                    (c: { name: string }) => c.name === val
                                )
                            );
                        }
                    })}
                    type="text"
                    placeholder=""
                    className="form-control"
                    id="divisionInput"
                />
                <input
                    aria-label="cancel"
                    type="button"
                    value="x"
                    className="bootstrap-selectize-input-clear-button state-agency-clear-button"
                />
                {divisionInputError && divisionInputError?.message instanceof String && (
                    <p className="error-text">{divisionInputError.message}</p>
                )}
                {divisionInputError && divisionInputError?.type === "validate" && (
                    <p className="error-text">{npdiForm.divisionCodeNotFound}</p>
                )}
            </div>
        </div>
    );
};

export default NpdiPayStubInfo;
