import { useQuery } from "@tanstack/react-query";
import { getDefaultCustomizationRetr } from "../../services/authentication/AuthenticationService";
import { QUERY_KEYS } from "../constants";
import { type AccountDefaultCustom } from "../../types";

const useDefaultCustomizationRtr = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_DEFAULT_CUSTOM_RTR],
        queryFn: (): Promise<AccountDefaultCustom> => getDefaultCustomizationRetr()
    });
};

export default useDefaultCustomizationRtr;
