import React, { createContext, useContext, useMemo, type ReactNode, useState } from "react";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import { Loader } from "gw-shared-components";

interface QueryParamsContextType {
    queryParams: Record<string, string>;
    setQueryParam: (key: string, value: string | null) => void;
}

const QueryParamContext = createContext<QueryParamsContextType | undefined>(undefined);

interface QueryParamProviderProps {
    children: ReactNode;
}

const IS_REACT = "true";

export const QueryParamProvider: React.FC<QueryParamProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    // Get the current location object from the router
    const location = useLocation();
    // Get the navigate function to programmatically navigate
    const navigate = useNavigate();
    const isArticlesSection = useMatch("/articles/*");
    // Memoize the query parameters to avoid unnecessary recalculations
    const queryParams = useMemo(() => {
        // Create a URLSearchParams object from the current location's search string
        const searchParams = new URLSearchParams(location.search);
        // Initialize an empty object to store the query parameters
        const params: Record<string, string> = {};
        // Iterate over each entry in the searchParams and add it to the params object
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }

        if (isArticlesSection) {
            setIsLoading(true);
            window.location.reload();
        }

        // Only add isReact=true if not in the "articles" section
        if (!params.isReact && !isArticlesSection) {
            setIsLoading(false);
            params.isReact = IS_REACT;
            // Add the isReact parameter to the searchParams
            searchParams.set("isReact", IS_REACT);
            // Update the URL with the new searchParams without adding a new entry to the history
            navigate({ search: searchParams.toString() }, { replace: true });
        }

        return params;
    }, [location.search, isArticlesSection, navigate]);

    // Function to set a query parameter
    const setQueryParam = (key: string, value: string | null) => {
        // Create a URLSearchParams object from the current location's search string
        const searchParams = new URLSearchParams(location.search);
        if (value) {
            // If a value is provided, set the query parameter
            searchParams.set(key, value);
        } else {
            // If no value is provided, delete the query parameter
            searchParams.delete(key);
        }
        // Ensure the isReact parameter is always present
        searchParams.set("isReact", IS_REACT);
        // Update the URL with the new searchParams
        navigate({ search: searchParams.toString() });
    };

    // Show a loading indicator while routing logic is resolving
    if (isLoading) {
        return (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <Loader />
            </div>
        );
    }

    return (
        // Provide the queryParams and setQueryParam function to the context
        <QueryParamContext.Provider value={{ queryParams, setQueryParam }}>
            {children}
        </QueryParamContext.Provider>
    );
};

// Custom hook to use the query parameters context
export const useQueryParams = (): QueryParamsContextType => {
    const context = useContext(QueryParamContext);
    if (!context) {
        throw new Error("useQueryParams must be used within a QueryParamProvider");
    }
    return context;
};
